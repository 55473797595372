import React from 'react';
import '../App.css';

export const Contact = () => {
  return (
    <div className="component-container" id="contact">
      <div className="contact-container">

      <div className="social-grid">
  <div className="grid-icon-container">
    <a href="https://www.instagram.com/eyestudio.arg" target="_blank" rel="noopener noreferrer">
      <img src={process.env.PUBLIC_URL + '/logotipo-de-instagram.png'} alt="Instagram" className="grid-icon" />
    </a>
  </div>
  <div className="grid-icon-container">
    <a href="https://www.linkedin.com/company/101526669" target="_blank" rel="noopener noreferrer">
      <img src={process.env.PUBLIC_URL + '/linkedin (2).png'} alt="LinkedIn" className="grid-icon" />
    </a>
  </div>
  <div className="grid-icon-container">
    <a href="https://vimeo.com/user220479395" target="_blank" rel="noopener noreferrer">
      <img src={process.env.PUBLIC_URL + '/vimeo (1).png'} alt="Vimeo" className="grid-icon" />
    </a>
  </div>
  <div className="grid-icon-container">
    <a href="https://www.youtube.com/@ey.estudio" target="_blank" rel="noopener noreferrer">
      <img src={process.env.PUBLIC_URL + '/youtube (1).png'} alt="YouTube" className="grid-icon" />
    </a>
  </div>
</div>
<div className='contact-mail'>
<a href='mailto:info@eyestudio.com.ar'><img src={process.env.PUBLIC_URL + '/redes_info@eyestudio.com.ar.png'} alt="infoMail" id='infoMail2'></img></a>

</div>

      </div>
    </div >
  );
};
