import React, { useState } from 'react';
import '../App.css';
import videoHome from '../Videos/EY REEL PLAY MUSICA OK.mp4';
import imageHome from '../Images/HOME_INICIO REEL.png';

export const Home = () => {
  const [play, setPlay] = useState(false);

  const handleImageClick = () => {
    setPlay(true); // Cambia a mostrar el video
  };


  return (
    <div className="component-container" id="home">
      <div className="home-container">
        {!play ? (
          <img 
            src={imageHome} 
            onClick={handleImageClick} 
            className="image-home"
            style={{ cursor: 'pointer' }} 
          />
        ) : (
          <video 
            loop
            controls
            muted
            autoPlay
            className="video-home"
            style={{ cursor: 'pointer' }}
          >
            <source src={videoHome} type="video/mp4" />
          </video>
        )}
      </div>
    </div>
  );
};
