import React, { useState } from 'react';
import '../App.css';
import logoEy from '../Images/logoEy.png'

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <div className="header">
      <div className="logo-container">
        <img src={logoEy} className="App-logo" alt="logo" />
      </div>
      <button className="menu-button" onClick={toggleMenu}>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
        <span className="hamburger-line"></span>
      </button>
      <div className={`menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><a href="#home" onClick={toggleMenu}>HOME</a></li>
          <li><a href="#work" onClick={toggleMenu}>WORK</a></li>
          <li><a href="#about" onClick={toggleMenu}>ABOUT</a></li>
          <li><a href="#contact" onClick={toggleMenu}>CONTACT</a></li>
        </ul>
      </div>
    </div>
  );
};

export default Header;
