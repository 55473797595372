import React, { useEffect, useState } from 'react';
import '../App.css';
import logoEy from '../Images/logoEy.png';

const Sidebar = () => {
    const [activeSection, setActiveSection] = useState('');

    //Funciones para marcar en la Sidebar en seccion se encuentra al clickear o scrollear
    useEffect(() => {
        const handleScroll = () => {
            const sections = ['home', 'work', 'about', 'contact'];

            for (const section of sections) {
                const element = document.getElementById(section);
                if (element && isElementInViewport(element)) {
                    setActiveSection(section);
                    break;
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const isElementInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        const visibleTop = rect.top <= windowHeight / 2;
        const visibleBottom = rect.bottom >= windowHeight / 2;

        return visibleTop && visibleBottom;
    };

    return (
        <div className="sidebar">
            <img src={logoEy} className="App-logo" alt="logo" />
            <ul>
                <li>
                    <div className="horizontal-line line-top-left line1"></div>
                </li>
                <li className={activeSection === 'home' ? 'active' : ''}>
                    <a href="#home">HOME</a>
                </li>
                <li className={activeSection === 'work' ? 'active' : ''}>
                    <a href="#work">WORK</a>
                </li>
                <li className={activeSection === 'about' ? 'active' : ''}>
                    <a href="#about">ABOUT</a>
                </li>
                <li className={activeSection === 'contact' ? 'active' : ''}>
                    <a href="#contact">CONTACT</a>
                </li>
                <li className='header-mail'>
                <a href='mailto:info@eyestudio.com.ar'><img src={process.env.PUBLIC_URL + '/redes_info@eyestudio.com.ar.png'} alt="infoMail" id='infoMail'></img></a>
                </li>
                <li>
                    <div className='social-icon-container'>
                        <a href="https://www.linkedin.com/company/101526669" target="_blank" rel="noopener noreferrer" className="social-link">
                            <img src={process.env.PUBLIC_URL + '/linkedin (2).png'} alt="LinkedIn" className="social-icon" />
                        </a>
                    </div>

                    <div className='social-icon-container'>
                        <a href="https://www.instagram.com/eyestudio.arg" target="_blank" rel="noopener noreferrer" className="social-link">
                            <img src={process.env.PUBLIC_URL + '/logotipo-de-instagram.png'} alt="Instagram" className="social-icon" />
                        </a>
                    </div>

                    <div className='social-icon-container'>
                        <a href="https://www.youtube.com/@ey.estudio" target="_blank" rel="noopener noreferrer" className="social-link">
                            <img src={process.env.PUBLIC_URL + '/youtube (1).png'} alt="YouTube" className="social-icon" />
                        </a>
                    </div>

                    <div className='social-icon-container'>
                        <a href="https://vimeo.com/user220479395" target="_blank" rel="noopener noreferrer" className="social-link">
                            <img src={process.env.PUBLIC_URL + '/vimeo (1).png'} alt="Vimeo" className="social-icon" />
                        </a>
                    </div>
                </li>
                <li>
                    <div className="horizontal-line line-top-left line1"></div>
                </li>
            </ul>
        </div>
    );
};

export default Sidebar;

