import './App.css';
import { About } from './components/About';
import { Contact } from './components/Contact';
import { Home } from './components/Home';
import Sidebar from './components/SideBar'
import { Work } from './components/Work';
import Header from './components/Header';

function App() {
  return (
    <>
    <Header/>
    <div className="App">
      
      <Sidebar/>
      <div className="Body">
      <div className="content">
      <Home/>
      <Work/>
      <About/>
      <Contact/>
      </div>
      </div>
    </div>
    </>
  );
}

export default App;
