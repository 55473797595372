import React from 'react'
import '../App.css';

export const About = () => {
    return (
        <div className="component-container" id="about">
            <div className="about-container">
            </div>
        </div>
    );
};

