import React, { useState, useEffect } from 'react';
import '../App.css';

export const Work = () => {

    const imagePaths = [
        require('../Images/CF_UBA.png'),
        require('../Images/CF_EUROLAT.png'),
        require('../Images/CF_AA.png'),
        require('../Images/CF_DEBATE.png'),
        require('../Images/CF_SUPERAUTOS.png'),
        require('../Images/CF_JUACAS.png'),
        require('../Images/CF_SENADO.png'),
        require('../Images/CF_GAMER.png'),
        require('../Images/CF_FORO.png'),
        require('../Images/CF_JM.png'),
        require('../Images/CF_PS.png'),
        require('../Images/CF_ALEPH.png'),
    ];

    const textPaths = [
        require('../Images/CT_UBA.png'),
        require('../Images/CT_EUROLAT.png'),
        require('../Images/CT_AA.png'),
        require('../Images/CT_DEBATE.png'),
        require('../Images/CT_SUPERAUTOS.png'),
        require('../Images/CT_JUACAS.png'),
        require('../Images/CT_SENADO.png'),
        require('../Images/CT_GAMER.png'),
        require('../Images/CT_FORO.png'),
        require('../Images/CT_JM.png'),
        require('../Images/CT_PS.png'),
        require('../Images/CT_ALEPH.png'),
    ];

    const videoPaths = [
        require('../Videos/UBA200.mp4'),
        require('../Videos/EUROLAT.mp4'),
        require('../Videos/ALERTAAEROPUERTOS.mp4'),
        require('../Videos/DEBATE.mp4'),
        require('../Videos/SUPERAUTOS.mp4'),
        require('../Videos/JUACAS.mp4'),
        require('../Videos/SENADO.mp4'),
        require('../Videos/GAMER.mp4'), // CAMBIAR GAMER
        require('../Videos/FOROMUNDIAL.mp4'),
        require('../Videos/JM.mp4'),  // CAMBIAR JM
        require('../Videos/PARQUESANITARIO.mp4'),
        require('../Videos/ALEPH.mp4'),  // CAMBIAR ALEPH
    ];


    const titles = [
        "UBA 200",
        "EUROLAT",
        "AA",
        "DEBATE",
        "SUPER AUTOS",
        "JUACAS",
        "MUSEO SENADO",
        "GAMER",
        "III FORO DD.HH.",
        "JM",
        "PARQUE SANITARIO",
        "ALEPH",
    ];

    const mdpeArray = [
        { motion: true, design: false, production: true, experience: true, cliente: "Universidad Nac. de Buenos Aires" },
        { motion: true, design: false, production: true, experience: true, cliente: "Eurolat" },
        { motion: true, design: true, production: false, experience: false, cliente: "OMAVA" },
        { motion: true, design: true, production: false, experience: false, cliente: "CAPIT" },
        { motion: true, design: false, production: true, experience: true, cliente: "NIPPUR para Discovery Mexico" },
        { motion: true, design: false, production: true, experience: true, cliente: "Disney Brasil" },
        { motion: true, design: true, production: true, experience: false, cliente: "Honorable Senado de la Nación" },
        { motion: true, design: true, production: false, experience: false, cliente: "PLANTA ALTA para Flow" },
        { motion: true, design: false, production: true, experience: true, cliente: "UNESCO" },
        { motion: true, design: false, production: true, experience: true, cliente: "Juan Massimo" },
        { motion: true, design: false, production: true, experience: true, cliente: "Gobierno Provincia Buenos Aires" },
        { motion: true, design: false, production: true, experience: true, cliente: "Animación independiente" },
    ];

    const [showVideo, setShowVideo] = useState(false);
    const [selectedVideo, setSelectedVideo] = useState('');
    const [activeSection, setActiveSection] = useState('');
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [infoIndex, setInfoIndex] = useState(null);

    // Función para abrir el video correspondiente al hacer clic en la imagen
    const handleImageClick = (videoSrc, index) => {
        setSelectedVideo(videoSrc);
        setShowVideo(true);
        setInfoIndex(index);
    };

    // Función para cerrar el video
    const closeVideo = () => {
        setSelectedVideo('');
        setShowVideo(false);
        setInfoIndex(null);
    };

    const handleMouseEnter = (index) => {
        setHoveredIndex(index);
    };

    // Función para manejar el mouse saliendo de un cuadrado
    const handleMouseLeave = () => {
        setHoveredIndex(null);
    };

    //UseEffect para cerrar el video al cambiar la sección
    useEffect(() => {
        const handleScroll = () => {
            const sections = ['home', 'about', 'contact'];

            for (const section of sections) {
                const element = document.getElementById(section);
                if (element && isElementInViewport(element)) {
                    closeVideo();
                    break;
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [activeSection]);

    const isElementInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        const visibleTop = rect.top <= windowHeight / 10;
        const visibleBottom = rect.bottom >= windowHeight * 0.9;

        return visibleTop && visibleBottom;
    };

    return (
        <div className="component-container" id="work">
            <div className="work-container">
                {showVideo && (
                    <div style={{ flexDirection: 'column', marginTop: '12vh' }}>
                        <video controls autoPlay loop muted className='video-work'>
                            <source src={selectedVideo} type="video/mp4" />
                        </video>
                        <div className='info-container'>
                            <span className="info-title">
                                {titles[infoIndex] === "III FORO DD.HH." ? (
                                    <>
                                        III FORO<br />
                                        DD.HH.
                                    </>
                                ) : (
                                    <>
                                        {titles[infoIndex].split(' ').map((word, i) => (
                                            <React.Fragment key={i}>
                                                {i > 0 && <br />}
                                                {word}
                                            </React.Fragment>
                                        ))}
                                        {titles[infoIndex].split(' ').length === 1 && <br />}
                                        &nbsp;
                                    </>
                                )}
                            </span>

                            <div>
                                {mdpeArray[infoIndex].motion && (
                                    <span className='info-bold'>MOTION</span>
                                )}
                                {mdpeArray[infoIndex].design && (
                                    <span className='info-bold'>/ DESIGN</span>
                                )}
                                {mdpeArray[infoIndex].production && (
                                    <span className='info-bold'>/ PRODUCTION</span>
                                )}
                                {mdpeArray[infoIndex].experience && (
                                    <span className='info-bold'>/ EXPERIENCE</span>
                                )}
                            </div>
                            <span className="info-subtitle">{mdpeArray[infoIndex].cliente}</span>
                        </div>
                        <button className="button-close" onClick={closeVideo}>x</button>
                    </div>
                )}

                {!showVideo && (
                    <div className="image-matrix">
                        {imagePaths.map((path, index) => (
                            <div
                                key={index}
                                className="image-container"
                                onClick={() => handleImageClick(videoPaths[index], index)}
                                onMouseEnter={() => handleMouseEnter(index)}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div
                                    className="image-background"
                                    style={{
                                        backgroundImage: `url(${hoveredIndex === index ? imagePaths[index] : textPaths[index]})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        backgroundRepeat: 'no-repeat'
                                    }}
                                >
                                </div>
                            </div>

                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
